.token-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.digit {
  width: 2rem;
  height: 2.5rem;
  overflow: hidden;
  position: relative;
  margin: 0 0.2rem;
  background-color: #333;
  border-radius: 4px;
  color: white;
}

.digit-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: top 1s cubic-bezier(0.23, 1, 0.32, 1); /* Smooth animation */
  font-size: 2rem;
  text-align: center;
}

.digit-inner::before {
  content: "0 1 2 3 4 5 6 7 8 9";
  display: block;
  line-height: 2.5rem; /* Matches the height of the .digit element */
  height: 25rem; /* 10 * digit height */
}

.digit-inner[data-digit="0"] { top: -0rem; }
.digit-inner[data-digit="1"] { top: -2.5rem; }
.digit-inner[data-digit="2"] { top: -5rem; }
.digit-inner[data-digit="3"] { top: -7.5rem; }
.digit-inner[data-digit="4"] { top: -10rem; }
.digit-inner[data-digit="5"] { top: -12.5rem; }
.digit-inner[data-digit="6"] { top: -15rem; }
.digit-inner[data-digit="7"] { top: -17.5rem; }
.digit-inner[data-digit="8"] { top: -20rem; }
.digit-inner[data-digit="9"] { top: -22.5rem; }