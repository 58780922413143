.fiawords {
    font-size: 5rem;
    width: min-content;
    height: min-content;
    transition: color 0.3s;
}
.fiawords:hover{
    color: var(--hover-color);
}
.fiawordsExtra {
    font-size: 2rem;
    opacity: 0;
    transition: all 0.5s;
}

@media screen and (max-width: 749px) {
    .fiawordsExtra {
        font-size: 2rem;
        opacity: 1;
        transition: all 0.5s;
    }
    .fiawords {
        font-size: 5rem;
        color: var(--hover-color);
        width: min-content;
        height: min-content;
        transition: color 0.3s;
    }
  }

.fiawords:hover ~ .fiawordsExtra {
    transition: all 0.5s;
    opacity: 1;
    font-size: 2rem;
}