@keyframes flow {
    0%{
        transform: translateY(100%);
        visibility: hidden;
    }
    20%{
        transform: translateY(100%);
        visibility: hidden;
    }
    23%{
        visibility: visible;
    }
    30%{
        transform: translateY(0);
    }
    90%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(-105%);
    }
}

@keyframes flow-0 {
    0%{
        transform: translateY(0%);
        visibility: visible;
        opacity: 1;
    }
    15%{
        transform: translateY(0%);
        visibility: visible;
        opacity: 1;
    }
    25%{
        transform: translateY(-105%);
        visibility: visible;
        opacity: 0.5
    }
    35%{
        transform: translateY(-105%);
        visibility: hidden;
        opacity: 0;
    }
    37.5%{
        transform: translateY(100%);
        visibility: hidden;
        opacity: 0;
    }
    50%{
        transform: translateY(100%);
        visibility: hidden;
    }
    90%{
        transform: translateY(100%);
        visibility: hidden;
        opacity: 0;
    }
    92%{
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        visibility: visible;
        opacity: 1
    }
}
@keyframes flow-1 {
    0%{
        transform: translateY(100%);
        visibility: hidden;
    }
    15%{
        transform: translateY(100%);
        visibility: hidden;
        opacity: 0;
    }
    17.5%{
        opacity: 1;
    }
    25%{
        transform: translateY(0);
        visibility: visible;
        opacity: 1
    }
    40%{
        transform: translateY(0);
        visibility: visible;
        opacity: 1
    }
    50%{
        transform: translateY(-105%);
        visibility: visible;
        opacity: 0.5
    }
    60%{
        transform: translateY(-105%);
        opacity: 0;
        visibility: hidden;
    }
    100% {
        transform: translateY(100%);
        opacity: 0;

        visibility: hidden;
    }
}
@keyframes flow-2 {
    0%{
        transform: translateY(100%);
        visibility: hidden;
        opacity: 0;
    }
    40%{
        transform: translateY(100%);
        visibility: hidden;
        opacity: 0
    }
    42%{
        opacity: 1
    }
    50%{
        transform: translateY(0);
        visibility: visible;
        opacity: 1
    }
    65%{
        transform: translateY(0);
        visibility: visible;
        opacity: 1
    }
    75% {
        transform: translateY(-105%);
        visibility: visible;
        opacity: 0.5
    }
    77%{
        transform: translateY(-105%);
        opacity: 0;
        visibility: hidden;
    }
    80% {
        transform: translateY(100%);
        visibility: hidden;
        opacity: 0

    }
    100%{
        transform: translateY(100%);
        visibility: hidden;
        opacity: 0;
    }
}

@keyframes flow-3 {
    0%{
        transform: translateY(100%);
        visibility: hidden;
        opacity: 0;
    }
    65%{
        transform: translateY(100%);
        visibility: hidden;
        opacity: 0;
    }
    67%{
        opacity: 1;
    }
    75% {
        transform: translateY(0);
        visibility: visible;
        opacity: 1
    }
    90%{
        transform: translateY(0);
        visibility: visible;
        opacity: 1
    }
    99.9% {
        transform: translateY(-105%);
        visibility: visible;
        opacity: 0.5
    }
    100% {
        transform: translateY(-105%);
        opacity: 0;
        visibility: hidden;
    }
}



@keyframes flow-in {
    0%{
        transform: translateY(100%);
    }
    20% {
        transform: translateY(100%);
    }
    80%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(-100%);
    }
}

@keyframes flow-out-first {
    0%{
        transform: translateY(0);
    }
    40% {
        transform: translateY(0);
    }
    60%{
        transform: translateY(-100%);
        visibility: hidden;
    }
    100% {
        transform: translateY(-100%);
        visibility: hidden;

    }
}

@keyframes flow-out {
    0%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(-100%);
    }
}


