@keyframes shapeDividerDanceTop {
    0% {
        transform: translateX(0) scale(100%);
    }
    100%{
        transform: translateX(10%) scale(110%);
    }
}

@keyframes shapeDividerDanceBottom {
    0% {
        transform: translateX(0) scale(100%);
    }
    100%{
        transform: translateX(-10%) scale(110%);
    }
}