@keyframes emojiPop {
    0% {
        transform: translateY(0);
        opacity: 0
    }
    15% {
        transform: translateY(0);
        opacity: 1
    }
    85% {
        transform: translateY(-50%);
        opacity: 0
    }
    100% {
        transform: translateY(-50%);
        opacity: 0
    }
}
