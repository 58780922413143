
.courier-prime-regular {
    font-family: "Courier Prime", monospace;
    font-weight: 400;
    font-style: normal;
}
@media screen and (max-width: 749px) {
    wrapper {
      height: min-content;
    }
  }
.wrapper {
    height: max-content;
    /* background-color: #FFC120; */
}

.wrapper .phoneWrapper {
    display: flex;
    position: absolute;
    overflow: hidden;
    transition: transform 1.25s ease-in;
}

.wrapper .rectangle {
    border: 0.45rem solid;
    border-color: #000000;
    left: 0;
    position: absolute;
    top: 0;
    overflow: hidden;
    
}
.wrapper .cameraBar {
    width: 6rem;
    height: 1rem;
    background-color: rgb(14, 14, 14);
}

.wrapper .overlap-group {
    position: relative;
    transition: opacity 1.25s ease-in;
    font-size: 7vw;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.wrapper .text-wrapper-0 {
    color: #ffffff;
    font-family: "Dela Gothic One", sans-serif;
    font-weight: 400;
    letter-spacing: 0;
    position: relative;
    text-align: center;
    font-size: 7rem;
}

.wrapper .text-wrapper-1 {
    color: #ffffff;
    font-family: "Dela Gothic One", sans-serif;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    height: 20%;
    
}

.wrapper .text-wrapper-2 {
    color: #ffffff;
    font-family: "Dela Gothic One", sans-serif;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
}

.wrapper .icons {
    left: 82% !important;
}
.wrapper .hand-thumbs-up {
    height: 35px !important;
    position: absolute !important;
    top: 15rem !important;
    width: 35px !important;
    z-index: 5;
}
.wrapper .hand-thumbs-down {
    height: 35px !important;
    position: absolute !important;
    top: 19rem !important;
    width: 35px !important;
}

.wrapper .chat-right-text {
    height: 35px !important;
    position: absolute !important;
    top: 23rem !important;
    width: 35px !important;
    z-index: 1;
}
.wrapper .reply-instance {
    height: 35px !important;
    position: absolute !important;
    top: 26rem !important;
    width: 35px !important;
}
.wrapper .text-wrapper-3 {
    color: #000000;
    font-family: "Courier Prime", monospace;
    font-weight: 400;
    height: 34px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    bottom: 0;
    white-space: nowrap;
    width: 190px;
    z-index: 10;
    visibility: visible;
}
.wrapper .arrow-right {
    color: #000000;
    left: 37vw;
    position: absolute;
    top: 85vh;
    font-size: 5rem;
}

.wrapper .arrow-left {
    color: #000000;
    left: 59.5vw;
    position: absolute;
    top: 40vh;
    font-size: 5rem;
    
}
.wrapper .text-wrapper-4 {
    color: #000000;
    font-family: "Courier Prime", monospace;
    font-size: 48px;
    font-weight: 400;
    height: 62px;
    left: 22vw;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 75vh;
    width: 276px;
}
.wrapper .text-wrapper-5 {
    color: #000000;
    font-family: "Courier Prime", monospace;
    font-size: 48px;
    font-weight: 400;
    height: 122px;
    left: 65vw;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 250px;
    width: 302px;
}
.wrapper .overlap-2 {
    height: 20vh;
    position: absolute;
    width: 50vw;
    align-items: center;
    top: 3vh; 
}
.wrapper .text-wrapper-6 {
    color: #ffffff;
    font-family: "Courier Prime", monospace;
    font-size: 6vw;
    font-weight: 400;
    height: 174px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 3vw;
    width: 100%;
    text-wrap: nowrap;
}

.title-text {
    font-size: 2.4rem;
}

.phoneVideo {
    position: relative;
    height: 120%;
    width: 120%;
    min-width: 120%;
    min-height: 120%;
    top: -10%
}
