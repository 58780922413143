

.medal-bronze {
    background-image: linear-gradient(133deg, #D47C17 15.85%, #FFCF8B 39.6%, #E09033 55.77%, #B46517 76.77%);
    border-color: #F49D3F;
}

.medal-silver {
    background-image: linear-gradient(133deg, #B6B4AE 15.85%, #F4F4F1 39.6%, #CDCCCB 55.77%, #A5A5A5 76.77%);
    border-color: #cfd7f2;
}

.medal-gold {
    background-image: linear-gradient(132deg, #FEC81B 29.05%, #FFDF7C 41.19%, #FFC300 51.23%, #EBAE07 68.11%, #E0AB13 85%);
    border-color: #FDD56E
}

.medal-primary {
    background-image: linear-gradient(133deg, #FFA600 15.85%, #FFE6B7 39.6%, #FFAE00 55.77%, #DF9307 76.77%);
    border-color: #FEC81B;
}
.medal-gray {
    background-color: #b5b5b5;
    border-color: #9e9e9e;
}

.medal {
    border-width: 5px;
    border-style: solid;
}

/* .medal:hover {
    animation: medalDance 0.5s ease-in-out 1 forwards;
} */

.medal > .medal-bronze {
    filter: drop-shadow(2px 2px 5px #B46517)
}
.medal > .medal-silver {
    filter: drop-shadow(2px 2px 5px #A5A5A5)
}
.medal > .medal-gold {
    filter: drop-shadow(2px 2px 5px #E0AB13)
}
.medal > .medal-primary {
    filter: drop-shadow(2px 2px 5px #DF9307)
}
.medal > .medal-gray {
    filter: drop-shadow(2px 2px 5px #EFEFED)
}

/* @-webkit-keyframes medalDance {
    0%{background-position:0% 0%}
    50%{background-position:100% 100%}
    100%{background-position:0% 0%}
}
@-moz-keyframes medalDance {
    0%{background-position:0% 0%}
    50%{background-position:100% 100%}
    100%{background-position:0% 0%}
} */
@keyframes medalDance { 
    0%{background-position:40% 0%}
    50%{background-position:100% 0%}
    100%{background-position:40% 0%}
}